import * as React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import Slider from '@mangoart/gatsby-ui/components/ezagrar/EZAgrarSlider';
import TeaserText from '@mangoart/gatsby-ui/components/ezagrar/TeaserText/TeaserText';
import TeaserBoxes from '@mangoart/gatsby-ui/components/ezagrar/TeaserBoxes/TeaserBoxes';
import Slugger from '@mangoart/gatsby-ui/helper/Slugger';
import DefaultLayout from '../layout/DefaultLayout';

const IndexPage = ({ data, location }) => {
  const { slides, siteData, pageData, news } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph } = pageData;

  function transformDate(dateString) {
    // format dd.mm.yyy
    const parts = dateString.split('.');
    return new Date(parts[2], parseInt(parts[1]) - 1, parts[0]);
  }

  function sortByDate(entry1, entry2) {
    return new Date(entry2.node.date).getTime() - new Date(entry1.node.date).getTime();
  }

  function filterExpired(node) {
    if (node.node.expirationdate <= 0) {
      return node;
    }
    return new Date(node.node.expirationdate).getTime() < new Date().getTime() ? null : node;
  }

  const teaserBoxes = news.edges
    .sort(sortByDate)
    .filter(filterExpired)
    .map(({ node }) => {
      return {
        node: {
          id: node.id,
          title: node.title,
          published: true,
          teaser: node.teaser,
          alttext: '',
          image: {
            ...node.teaserimage,
          },
          actionlabel: 'Weiterlesen',
          link: `/news/${Slugger.slug(node.title)}`,
        },
      };
    })
    .filter((item, index) => index < 4);

  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <Slider data={slides.edges} />
      <TeaserText
        data={{
          title: 'Europas führender Laderhersteller',
          text: 'Schäffer Lader steht für qualitativ hochwertige innovative Produkte bei Hofladern, Radladern und Teleradladern. Der deutsche Hersteller entwickelt und fertigt seit 1979 Hoflader in den eigenen Werkshallen in Erwitte. Das Produktprogramm von Schäffer umfasst bei Hofladern den Leistungsbereich von 20 bis 60 PS, bei Radladern von 60 bis 130 PS und bei Teleradladern bewegt sich das Leistungsspektrum zwischen 50 und 210 PS. Weiters bietet Schäffer Lader ein umfassendes Sortiment an Zusatzgeräten passend für jeden Einsatz an. Für den professionellen Einsatz in Landwirtschaft, Garten- und Landschaftsbau, Kommunen und Bauwirtschaft sind diese Geräte entwickelt worden. Die Synthese aus größtmöglicher Leistung, kompakten Abmessungen, Sicherheit und Komfort finden Sie so nur bei Schäffer Lader.',
          link: null,
          label: null,
        }}
      />
      <TeaserBoxes data={teaserBoxes} />
    </DefaultLayout>
  );
};

export default IndexPage;

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    news: allCockpitNews(filter: { published: { eq: true } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          teaser
          date
          expirationdate
          teaserimage {
            childImageSharp {
              gatsbyImageData(width: 340, aspectRatio: 2)
            }
          }
          images {
            alttext
            image {
              childImageSharp {
                gatsbyImageData(width: 340, aspectRatio: 2)
              }
            }
          }
        }
      }
    }

    slides: allCockpitSlides(filter: { published: { eq: true } }, sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          title
          highlighted_line
          position
          text_color
          text_line_1
          text_line_2
          mobile_text_line_1
          mobile_text_line_2
          image {
            id
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
        }
      }
    }

    teaserboxes: allCockpitTeaserbox {
      edges {
        node {
          id
          title
          published
          teaser
          alttext
          image {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
          actionlabel
        }
      }
    }
    pageData: cockpitPageHomepage {
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
